.referral-hero-signup {
  form {
    max-width: 550px;
    height: 70px;
    position: relative;

    input {
      border: none;
      background: #f4f4f4;
      border-radius: 54px;
      width: calc(100% - 100px);
      height: 100%;
      font-size: 16px;
      padding: 0 50px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid rgba(255, 255, 255, 0.1);

      width: 70px;
      height: 70px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      font-size: 17px;
      transition: all 0.3s ease-in-out;
    }

    button:hover {
      background: #ff4a8b;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #0b0b0b;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #0b0b0b;
    }

    ::placeholder {
      color: #0b0b0b;
    }

    @media (max-width: 575px) {
      height: 60px;

      button {
        width: 60px;
        height: 60px;
      }
      input {
        width: calc(100% - 80px);
        padding: 0 20px;
      }
    }
  }

}

.hero-banner-six {
  .hero-sub-heading {
    padding-bottom: 60px;
  }

}

.accordion-style-blips {
  .card {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 17px !important;
    margin-bottom: 10px;
    background: transparent;

    @media (max-width: 575px) {
      .card-header button {
        padding-left: 15px;
        font-size: 20px;
      }
      .card-body {
        padding: 0 40px 20px 15px;
      }
    }

    .faq-section-four.bg-white {
      background: #f7f7f7;
    }

    .card-header {
      background: transparent;
      border-radius: 0;
      padding: 0;
      border: none;
    }

    .card-header button {
      display: block;
      width: 100%;
      text-align: left;
      padding: 27px 35px 27px 45px;
      font-family: "Rubik", sans-serif;
      font-size: 24px;
      border: none;
      border-radius: 0;
      margin: 0;
      color: white;
      text-decoration: none;
      position: relative;
    }

    .card-header button:before {
      content: "\f107";
      font-family: "font-awesome";
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 767px) {
        right: 12px;
      }
    }

    .card-body {
      padding: 0 50px 20px 45px;
    }

    .card-body p {
      padding-bottom: 15px;
    }

    .card-body p a {
      text-decoration: underline;
      color: var(--p-color);
    }
  }
}

.fancy-short-banner-seven {
  .referral-hero-signup {
    width: 500px;
  }
}

#contact-form {
  .ios-button {
    border-radius: 6px;

    &:before {
      border-radius: 6px;
    }

    &:after {
      display: none;
      visibility: hidden;
    }

  }
}

.ios-button {
  width: 200px;
  height: 65px;
  border-radius: 12px;
  color: #fff;
  background: #80FF75;
  border: none;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  z-index: 1;
  box-shadow: -2px 5px 12px 3px rgba(0, 0, 0, 0.2);


  &:hover {
    color: #000;
  }


  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(162.41deg, #0060FF 0%, #80FF75 100%);
    border-radius: 12px;
    transition: all 0.6s ease-in-out;
    opacity: 1;
    z-index: -1;
  }

  &:hover:before {
    opacity: 0;
  }

}

.ios-button:after {
  content: url(../images/shape/161.svg);
  position: absolute;
  right: -107px;
  bottom: -28px;
}

.ios-button .icon {
  margin-right: 15px;
}

.ios-button span {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}

.ios-button strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}


.blips-main {
  #product {
    position: relative;

    .block-style-twentyThree {
      position: relative;

      .bg-image-circles {
        position: absolute;
        height: 33vw;
        margin-left: -10vw;
        left: 0px;
        margin-top: -6vw;
        z-index: -1;
        transform: rotate(180deg);
      }

      &:before {
        content: "";
        position: absolute;
        width: 600px;
        height: 600px;
        border-radius: 50%;
        top: -27%;
        left: 1%;
        background: rgba(0, 96, 255, 0.1);
        filter: blur(400px);
        transform: rotate(150deg);
        will-change: transform;
      }

      &:after {
        content: "";
        position: absolute;
        width: 600px;
        height: 600px;
        border-radius: 50%;
        top: 5%;
        left: -8%;
        background: rgba(128, 255, 117, 0.15);
        filter: blur(400px);
        transform: rotate(150deg);
        will-change: transform;
      }

      &.right-image {
        .bg-image-circles {
          margin-right: -10vw;
          right: 0px;
          left: unset;
          margin-left: 0;
          transform: rotate(0deg);

        }

        &:before {
          left: initial;
          right: 1%;
          filter: blur(400px);

        }

        &:after {
          left: initial;
          right: -8%;
          filter: blur(400px);

        }
      }
    }

  }

  .block-style-twentyFour {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: none;
    padding: 40px;

  }


  [class*="bubble"] {
    position: absolute;
    z-index: -1;
    border-radius: 50%;
  }

  [class*="bubble"]:before {
    content: "";
    border-radius: 50%;
    position: absolute;
  }

  .bubble-one {
    width: 120px;
    height: 120px;
    left: 23%;
    top: -3%;
    animation: rotated 14s infinite linear;
  }

  .bubble-one:before {
    width: 8px;
    height: 8px;
    background: #58CDA1;
    top: 0;
    left: 50%;
  }

  .bubble-two {
    width: 160px;
    height: 160px;
    top: 28%;
    left: -21%;
    animation: rotatedTwo 25s infinite linear;
  }

  .bubble-two:before {
    width: 17px;
    height: 17px;
    background: #004ECE;
    top: 50%;
    right: 100%;
  }

  .bubble-three {
    width: 120px;
    height: 120px;
    bottom: -7%;
    left: -10%;
    animation: rotated 15s infinite linear;
  }

  .bubble-three:before {
    width: 7px;
    height: 7px;
    background: #80FF75;
    bottom: 0;
    left: 50%;
  }

  .bubble-four {
    width: 120px;
    height: 120px;
    right: 27%;
    bottom: -13%;
    animation: rotatedTwo 17s infinite linear;
  }

  .bubble-four:before {
    width: 12px;
    height: 12px;
    background: #58CDA2;
    bottom: 0;
    left: 50%;
  }

}

